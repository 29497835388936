@import-normalize;

/* Fonts. */

@font-face {
  font-family: 'RMNeue';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/RMNeue-Regular.woff2') format('woff2'),
  url('../assets/fonts/RMNeue-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'RMNeue';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/RMNeue-Italic.woff2') format('woff2'),
  url('../assets/fonts/RMNeue-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'RMNeue';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/RMNeue-Bold.woff2') format('woff2'),
  url('../assets/fonts/RMNeue-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'RMNeue';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/RMNeue-Black.woff2') format('woff2'),
  url('../assets/fonts/RMNeue-Black.woff') format('woff');
  font-display: swap;
}

/* Box-sizing. */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Basic typography. */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #fff;
  font-family: RMNeue,Helvetica Neue,Helvetica,Arial,sans-serif;
  line-height: 1.5;
  color: #000;
}

/* Layout. */

html,
body,
#root {
  height: 100%;
  margin: 0;
}
html {
  overflow-y: scroll;
}
#page {
  min-height: 100%;
}

/* HTML elements. */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 600;
  margin: 0;
}

h1 {
  font-weight: 900;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 1.5rem;
}
@media (min-width: 1000px) {
  h1 {
    font-size: 3em;
    margin-bottom: 2rem;
  }
}

h2, .h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}

h3, .h3 {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin-bottom: .5rem;
}

h4, .h4 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: .25rem;
}

h5, h6 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: .25rem;
}

/* Lower specificity, so it's easier to override when f.e. .button--black is applied */
@layer {
  a,
  a:link,
  a:visited,
  a:focus {
    transition: color 150ms ease;
    color: #000;
  }
  a:hover,
  a:active {
    color: #6d6d6d;
  }
}

a.no-decoration {
  text-decoration: none;
}
a.no-decoration:hover {
  text-decoration: underline;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

img {
  display: block;
  max-width: 100%;
}

svg {
  display: inline-block;
  fill: currentColor;
}

/* Forms. */
button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0 .5em;
  font-size: .875rem;
  font-weight: bold;
}

input[type="text"] {
  font-size: 14px;
}
@media (min-width: 786px) {
  input[type="text"] {
    font-size: 16px;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: text-bottom;
  cursor: pointer;
  border: 1px solid #999;
}
input[type="checkbox"]:checked {
  background-image: url('../assets/images/checkbox-checked.svg');
}

/* Utilities. */

/* A single line of white space: 1em times the default line height. */
.space-before {
  margin-top: 1.5em;
}

/* Unstyled lists, see also https://github.com/jensimmons/cssremedy/issues/15#issuecomment-462174177. */
.unstyled-list,
.unstyled-list--inline {
  margin: 0;
  padding: 0;
}
.unstyled-list > li {
  display: block;
}
.unstyled-list--inline > li {
  display: inline;
}
.unstyled-list > dd,
.unstyled-list > div > dd {
  margin-left: 0;
}
.piped > li,
.piped > li,
.piped > dd {
  display: inline;
}
.piped > li + li:before,
.piped > li + li:before,
.piped > dd + dd:before {
  content: "";
  margin: 0 10px;
  border-left: 1px solid #ccc;
}

/* Buttons (not necessarily applied to all <button> elements). */
/* Simply applying a .button class sets sizes but no colors. */
[class^='button'], [class*=' button'] {
  border: none;
  font-weight: bold;
  line-height: 1;
  padding: .5rem;
  display: inline-block;
  /* Min-width for a larger touch surface. */
  min-width: 44px;
  transition: color 200ms ease, background-color 200ms ease;
  cursor: pointer;
}
[class^='button'] button, [class*=' button'] button {
  cursor: pointer;
}

.button--reset {
  border: none;
  touch-action: manipulation;
  cursor: pointer;
  border-radius: 0px;
  user-select: none;
  background-color: transparent;
  color: currentColor;
  padding: 0;
  min-width: unset;
}

.button--black {
  background: #000;
  color: #fff;
}
.button--black:hover {
  background: #6d6d6d;
}
.button--black:focus {
  background: #999;
}
.button--black:active {
  background: #ccc;
}
.button--black:disabled {
  background: #f5f5f5;
  color: #999;
}

.button--large {
  font-size: 18px;
  padding: 1rem 1.5rem;
}

.button--white {
  background: #fff;
  color: #000;
}
.button--white:hover {
  color: #000;
  background: #f5f5f5;
}
.button--white:focus {
  background: #cccccc;
}
.button--white:active {
  background: #999999;
}
.button--white:disabled {
  color: #999;
}
.button--white.button--border {
  border: 1px solid #cccccc;
}

.button--gray {
  background: #f5f5f5;
  color: #000;
}
.button--gray:hover {
  color: #000;
  background: #f5f5f5;
}
.button--gray:focus {
  background: #cccccc;
}
.button--gray:active {
  background: #999999;
}
.button--gray:disabled {
  color: #999;
}
.button--gray.button--border {
  border: 1px solid #cccccc;
}

a[class^='button'], a[class*=' button'] {
  text-decoration: none;
}
a[class^='button']:hover, a[class*=' button']:hover {
  text-decoration: underline;
}

label[class^='button'], label[class*=' button'] {
  line-height: 0;
}

/* Links with SVG icons inside. */
a[class^='icon-link'], a[class*=' icon-link'] {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
}
a[class^='icon-link']:hover, a[class*=' icon-link']:hover {
  text-decoration: underline;
}
a.icon-link--icon-before svg {
  margin-right: .5em;
}
a.icon-link--icon-after svg {
  margin-left: .5em;
}

/* Visibility classes, from Drupal's hidden.module.css. */
.hidden {
  display: none;
}
.force-hidden {
  display: none!important;
}
.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}
.invisible {
  visibility: hidden;
}

/* Off-white background color. */
.background-alternate {
  background-color: #f5f5f5;
}

/* Default border radius. */
.rounded {
  border-radius: 2px;
}

/* Navigation. */

nav a {
  text-decoration: none;
}
nav a:hover,
nav a:active {
  text-decoration: underline;
}

/* Tables */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1.5rem;
}

th,
td {
  padding: 0;
  text-align: left;
  vertical-align: top;
}
